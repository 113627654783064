// modules/sv/functions.js
export async function getWishlist(listId) {
    console.log('getWishlist called');
    const query = `
      {
        wishlistItems(listId: ${listId}) {
          ... on Item {
            id
            variantOptionsTitle
            wishlistVariantOption
            wishlistVariantUrl
            newOptions
            quantity
            element {
              title
            }
          }
        }
      }
    `;
  
    try {
      const response = await fetch('/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ajp_ZgATyD-r_BlvWyMblGq1k3eFwMLx`, // Replace with window.WISHLIST_TOKEN later
        },
        body: JSON.stringify({ query }),
      });
  
      const data = await response.json();
      return data.data.wishlistItems;
    } catch (error) {
      console.error('Error fetching wishlist:', error);
      return null;
    }
  }
  
  // Expose getWishlist globally
  window.getWishlist = getWishlist;
  
  // Optional: Add an init function if you have more setup logic
  export function initFunctionsJS() {
    console.log('Functions JS initialized');
  }

  