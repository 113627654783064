// Function to decode the Json 
function decodeProductData(data) {
    return JSON.parse(data.replace(/&quot;/g, '"'));
}

// Function to show the frame variants for the selected color variant
function showVariantOptions(productId, itemVariantId, listId, secondVariantDetailsTitle) {
    var secondVariantDetailsTitle = secondVariantDetailsTitle || null;

    // console.log('showVariantOptions Function Called: ', productId, itemVariantId);

    // Hide all variant options for the specific product
    document.querySelectorAll('#product-' + productId + ' .variant-options').forEach(function (el) {
        el.style.display = 'none';
    });

    // Show the variant options for the selected item variant, if they exist
    var variantOptionsEl = document.getElementById('variant-options-' + productId + '-' + itemVariantId);
    if (variantOptionsEl) {
        variantOptionsEl.style.display = 'flex';
    }

    // Remove the selected and active classes from all color variant buttons within the product
    document.querySelectorAll('#product-' + productId + ' .variant-toggle-button-swatch, #product-' + productId + ' .variant-toggle-button').forEach(function (el) {
        el.classList.remove('variant-toggle-button-selected', 'active');
    });

    // Add the selected and active classes to the clicked color variant button
    var selectedButton = document.querySelector('#product-' + productId + ' .variant-toggle-button-swatch[onclick*="showVariantOptions(\'' + productId + '\', \'' + itemVariantId + '\', \'' + listId + '\', \'' + secondVariantDetailsTitle + '\')"]');
    console.log('selectedButton: ', selectedButton);
    if (selectedButton) {
        selectedButton.classList.add('variant-toggle-button-selected', 'active');
    }

    // Update the URL with the selected color variant
    updateUrlWithVariantOptions(productId, itemVariantId);
    let form = document.querySelector('#quick-add-to-cart-' + productId);
    let button = form.querySelector('button[type="submit"]');
    window.refreshWishlistStatus(productId, itemVariantId, secondVariantDetailsTitle, listId, button);

    updateFormFieldsWithVariantOptions(productId, itemVariantId, secondVariantDetailsTitle, true);
    // Update the main image to the selected variant's image
    var newImage = selectedButton.getAttribute('data-variant-hover-image');
    /* console.log('Category.js, located src/js/modules/sv/category.js', newImage); */
    var targetImageHolder = document.getElementById(selectedButton.getAttribute('data-target-hover-image-holder'));
    /* console.log('Category.js, located src/js/modules/sv/category.js', targetImageHolder); */

    if (newImage && targetImageHolder) {
        targetImageHolder.src = newImage;
        targetImageHolder.style.opacity = '1';
    }
    var variantOption = document.querySelector('#variant-options-' + productId + '-' + itemVariantId + ' [data-second-variant]');
    // Use Jquery to trigger the click event on the first variant option
    if (variantOption) {
        variantOption.click();
    }
};

// Function to update Form Fields with the selected variant and variant option
function updateFormFieldsWithVariantOptions(productId, itemVariantId, secondVariantName, isFirst) {

    var isFirst = isFirst || false;
    /* console.log(secondVariantName); */
    // Get the selected color variant
    var selectedVariantFirst = document.querySelector('#product-' + productId + ' [onclick="showVariantOptions(\'' + productId + '\', \'' + itemVariantId + '\')"]');
    /* console.log('selectedVariantFirst: ', selectedVariantFirst); */
    // Get the form fields
    var productQuickToCart = document.querySelector('form[data-add-to-cart-product-id="' + productId + '"]');

    // Update the form fields
    if (productQuickToCart) {
        productQuickToCart.querySelector('input[name="elementId"]').value = itemVariantId;
        productQuickToCart.querySelector('input[name="fields[wishlistVariantOption]"]').value = secondVariantName;
    }
    
}


// Function to update the URL with the selected variant and variant option
function updateUrlWithVariantOptions(productId, itemVariantId, variantOptionSlug) {
    // Get the current URL
    var currentUrl = new URL(window.location.href);

    // Get the selected color variant
    var selectedColorVariant = document.querySelector('#product-' + productId + ' .variant-toggle-button-swatch.variant-toggle-button-selected, #product-' + productId + ' .variant-toggle-button.variant-toggle-button-selected');
    var selectedColorVariantSlug = selectedColorVariant ? selectedColorVariant.getAttribute('title') : null;

    // Update the URL parameters
    if (selectedColorVariantSlug) {
        currentUrl.searchParams.set('variant', selectedColorVariantSlug);
    }
    if (variantOptionSlug) {
        currentUrl.searchParams.set('variantOption', variantOptionSlug);
    }

    // Push the updated URL to the browser history
    // window.history.pushState({}, '', currentUrl);
}

// Function to change the image to the selected variant's option image (Variant 2)
function updateVariantOptionImage(productId, imageURL, collectionItemUrl, activeButtonId , firstVariantId, secondVariantName, listId) {
    /* console.log('List Id: ', listId); */
    updateFormFieldsWithVariantOptions(productId, firstVariantId, secondVariantName, false);
    /* console.log('updateVariantOptionImage Function Called: ', 'product id: ', productId, 'imageURL ', imageURL, ' collectionItemUrl', collectionItemUrl);
    console.log('updateVariantOptionImage Function Called: ',  'activeButtonId' , activeButtonId , ' firstVariantId', firstVariantId, 'secondVariantName ', secondVariantName, ' ', listId); */
    var targetImageLink = document.querySelector('#collection-item-preview-tile-cover-images-' + productId);

    var targetQuickAddToCart = document.querySelector('[data-add-to-cart-product-id="' + productId + '"]');
    
    if (targetQuickAddToCart) {
        targetQuickAddToCart.setAttribute('data-quick-first-variant', firstVariantId);
    }
    targetImageLink.href = collectionItemUrl;

    var activeButton = document.querySelector('#' + activeButtonId);

    var targetImageHolder = document.querySelector('#collection-item-dynamic-image-holder-' + productId);
    if (targetImageHolder) {
        targetImageHolder.src = imageURL;
        targetImageHolder.style.opacity = '1';
    }
    
    
    // Remove the selected and active classes from all color variant buttons within the product
    document.querySelectorAll('#product-' + productId + ' .variant-toggle-button-swatch-option, #product-' + productId + ' .variant-toggle-button-option').forEach(function (el) {
        el.classList.remove('variant-toggle-button-selected', 'active');
    });

    // Add the selected and active classes to the clicked color variant button
    if (activeButton) {
        activeButton.classList.add('variant-toggle-button-selected', 'active');
    }

    /* ajaxReloadInLIST(productId); */
    let form = document.querySelector('#quick-add-to-cart-' + productId);
    let button = form.querySelector('button[type="submit"]');
    window.refreshWishlistStatus(productId, firstVariantId, secondVariantName, listId, button);
}

// Function to check if the variant option is in the list to show whether allowing to add to quote or not
function ajaxReloadInLIST(productId) {

   /*  $.get(window.location.pathname, function(data) {
        var updatedContent = $(data).find('#in-list-' + productId).html();
        if (updatedContent) {
            $('#in-list-' + productId).html(updatedContent);
        }
    }); */

    window.refreshWishlistStatus(productId);
}


function initPriceRangeSlider() {
    const parent = document.querySelector(".range-slider");
    if (!parent) return;

    const rangeInputs = parent.querySelectorAll("input[type=range]");
    const numberInputs = parent.querySelectorAll("input[type=number]");

    function updatePriceLabels(min, max) {
        document.getElementById('minPriceLabel').textContent = min;
        document.getElementById('maxPriceLabel').textContent = max;
    }

    function handleRangeInput() {
        let [min, max] = rangeInputs.map(input => parseFloat(input.value));
        if (min > max) [min, max] = [max, min];

        rangeInputs[0].value = min;
        rangeInputs[1].value = max;

        updatePriceLabels(min, max);
    }

    function handleNumberInput() {
        let [min, max] = numberInputs.map(input => parseFloat(input.value));
        if (min > max) [min, max] = [max, min];

        numberInputs[0].value = min;
        numberInputs[1].value = max;
        rangeInputs[0].value = min;
        rangeInputs[1].value = max;
    }

    rangeInputs.forEach(input => input.addEventListener('input', handleRangeInput));
    numberInputs.forEach(input => input.addEventListener('input', handleNumberInput));
}



let quoteCartCounters = null;

function updateQuoteCartCounters(newQuoteCount) {
	console.log('updateQuoteCartCounters from src/js/modules/sv/category.js' , newQuoteCount);

	quoteCartCounters = document.querySelectorAll(".quote-cart-count");

	quoteCartCounters.forEach((quoteCartCounter) => {
		quoteCartCounter.innerText = newQuoteCount;

		if (newQuoteCount > 0) {
			quoteCartCounter.classList.add("quote-cart-count-visible");
		} else {
			quoteCartCounter.classList.remove("quote-cart-count-visible");
		}
	});
}


let itemQuantityFields = null;
let removeFieldId = null;
let removeField = null;
let itemContainerId = null;
let itemContainer = null;

function initItemQuantityFields() {
    console.log('initItemQuantityFields from src/js/modules/sv/category.js' );
    itemQuantityFields = document.querySelectorAll(
        ".quote-panel-quantity-field"
    );

    itemQuantityFields.forEach((itemQuantityField) => {
        itemQuantityField.addEventListener("change", (event) => {
            // Add a 300ms delay so this event doesn't bubble the Fetch requests on the server
            setTimeout(function () {
                // get the value of this field, if its zero we want to check the hidden remove field for this item
                if (itemQuantityField.value < 1) {
                    // get the correct remove field
                    removeFieldId =
                        itemQuantityField.getAttribute("data-remove-field");
                    removeField = document.getElementById(removeFieldId);

                    removeField.checked = true;

                    updateQuoteQuantities();

                    // Hide the item from the user (even though its been removed from the list it's still present)
                    itemContainerId = itemQuantityField.getAttribute(
                        "data-item-container"
                    );
                    itemContainer = document.getElementById(itemContainerId);

                    itemContainer.remove();
                } else {
                    updateQuoteQuantities();
                }
            }, 300);
        });
    });
}



// Make the function globally accessible
window.updateQuoteCartCounters = updateQuoteCartCounters;
window.initItemQuantityFields = initItemQuantityFields;
window.initPriceRangeSlider = initPriceRangeSlider;
window.showVariantOptions = showVariantOptions;
window.updateUrlWithVariantOptions = updateUrlWithVariantOptions;
window.updateVariantOptionImage = updateVariantOptionImage;



document.addEventListener('DOMContentLoaded', () => {
    /* console.log('Category.js, located src/js/modules/sv/category.js'); */
});