import { collectionItemGalleries, collectionItemGallerySwiper } from './galleries.js';

// --------------------------------------------------------------- Variant select menu switches url based on the users selection

const variantSelectField = document.getElementById('variant-switcher');

function initVariantSelectField() {
    /* console.log('Selecting Image.'); */
	if (typeof(variantSelectField) != 'undefined' && variantSelectField != null) {

		variantSelectField.addEventListener('change', event => {
            
			window.location = variantSelectField.options[variantSelectField.selectedIndex].value;

		})
	}

}

// --------------------------------------------------------------- Variant option select menu 


document.addEventListener('DOMContentLoaded', () => {
    const initVariantOptions = document.querySelectorAll('[name="initVariantOption"]');
    /* console.log('Going to slide',initVariantOptions); */
    initVariantOptions.forEach(option => {
        if (option.hasAttribute('selected')) {
           // console.log(`Option with value ${option.value} is selected. Data-variant-gallery-target: ${option.getAttribute('data-variant-gallery-target')}`);
            targetGallerySlide = option.getAttribute('data-variant-gallery-target');
            targetGallery = collectionItemGallerySwiper;

			goToGallerySlide(targetGallery, targetGallerySlide);
            
        }
    });
});


const variantOptionSelectField = document.getElementById('variant-options-switcher');

let variantOptionStatus = null;
let variantOptionFavStatus = null;
let variantOptionPrice = null;
let variantAssetID = null;
let targetGallerySlide = null;
let targetGallery = null;

function initVariantOptionSelectField() {
	if (typeof(variantOptionSelectField) != 'undefined' && variantOptionSelectField != null) {
        /* console.log('Variant Option Select Field',variantOptionSelectField); */
		variantOptionSelectField.addEventListener('change', event => {

			updateWishlistVariantOptionField(variantOptionSelectField.value);

			// get the selected options list status
			variantOptionStatus = variantOptionSelectField.selectedOptions[0].getAttribute('data-variant-option-in-list');

            variantOptionFavStatus = variantOptionSelectField.selectedOptions[0].getAttribute('data-variant-option-in-fav');

			testVariantOptionInList(variantOptionStatus, variantOptionFavStatus);

			// lets get the variant options price
			variantOptionPrice = variantOptionSelectField.selectedOptions[0].getAttribute('data-variant-option-price');
			updateVariantPrice(variantOptionPrice);

			// If the variantOption has a specific slide lets pass that asset to a hidden field so we can show it on the wishlist
			variantAssetID = variantOptionSelectField.selectedOptions[0].getAttribute('data-variant-gallery-asset');
            /* console.log('Variant Asset ID',variantAssetID); */
			updateListItemImageField(variantAssetID);

			// Lets see if a gallery exists and if so initiate sliding to a specific slide

			if (collectionItemGalleries.length > 0) {

				targetGallerySlide = variantOptionSelectField.selectedOptions[0].getAttribute('data-variant-gallery-target');
				targetGallery = collectionItemGallerySwiper;

				goToGallerySlide(targetGallery, targetGallerySlide);

			}
		})
	}
}
// --------------------------------------------------------------- Variant option toggles

let variantOptionToggles = document.querySelectorAll('.item-variant-option-toggle');

function initVariantOptionToggles() {

	variantOptionToggles.forEach(item => {

		item.addEventListener('click', event => {

			updateWishlistVariantOptionField(item.querySelector("input").value);

			updateVariantOptionTitle(item.title);

			variantOptionStatus = item.getAttribute('data-variant-option-in-list');
            variantOptionFavStatus = item.getAttribute('data-variant-option-in-fav');
			testVariantOptionInList(variantOptionStatus, variantOptionFavStatus);

			// lets get the variant options price
			variantOptionPrice = item.getAttribute('data-variant-option-price');
			updateVariantPrice(variantOptionPrice);

			// If the variantOption has a specific slide lets pass that asset to a hidden field so we can show it on the wishlist
			variantAssetID = item.getAttribute('data-variant-gallery-asset');
			updateListItemImageField(variantAssetID);
            /* console.log('Variant Asset ID',variantAssetID); */
            
			// Lets see if a gallery exists and if so initiate sliding to a specific slide

			if (collectionItemGalleries.length > 0) {

				targetGallerySlide = item.getAttribute('data-variant-gallery-target');
				targetGallery = collectionItemGallerySwiper;

				goToGallerySlide(targetGallery, targetGallerySlide);

			}

		})

	})

}

// --------------------------------------------------------------- Update Variant Price

let variantPriceField = null;

function updateVariantPrice(price) {
	// and update the price field if it exists
	variantPriceField = document.getElementById('variant-price');

	if (typeof(variantPriceField) != 'undefined' && variantPriceField != null) {
		variantPriceField.innerHTML = price;
	}
}

// --------------------------------------------------------------- updateWishlistVariantOptionField

const wishlistVariantOptionField = document.getElementById('wishlist-variant-option-field');

function updateWishlistVariantOptionField(variantOption) {
	wishlistVariantOptionField.value = variantOption;
    // Get the current URL
    const url = new URL(window.location);

    // Update or add the 'variantOption' parameter
    url.searchParams.set('variantOption', variantOption);

    // Log the updated URL to the console for debugging
    /* console.log(url); */

    // Push the new URL to the address bar without reloading the page
    history.pushState({}, '', url);
}

// --------------------------------------------------------------- updateListItemImageField

const listItemImageField = document.getElementById('list-item-image');

function updateListItemImageField(assetId) {
	listItemImageField.value = assetId;
}

// --------------------------------------------------------------- updateVariantOptionTitle

const variantOptionTitleSpan = document.getElementById('selected-variant-option-title');

function updateVariantOptionTitle(variantOptionTitle) {
	variantOptionTitleSpan.innerHTML = variantOptionTitle;
}

// --------------------------------------------------------------- goToGallerySlide

function goToGallerySlide(targetSwiper, targetSlide) {
	targetSwiper.slideTo(targetSlide);
}

// --------------------------------------------------------------- 

const variantStatusAlert = document.getElementById('variant-status');

function testVariantOptionInList(optionInListStatus, optionInFavStatus) {
    const favIcons = document.querySelectorAll('.fav-btn');
    /* const favFilledIcons = document.querySelectorAll('.fav-filled-icon'); */

    if (optionInListStatus == 'true') {
        document.getElementById("wishlist-submit-btn").disabled = true;
        document.getElementById("wishlist-submit-btn").value = 'Added to Quote';
        document.getElementById("wishlist-submit-btn").classList.add('pointer-events-none');
    } else {
        /* console.log('this selection isnt in your list.'); */
        document.getElementById("wishlist-submit-btn").disabled = false;
        document.getElementById("wishlist-submit-btn").value = 'Add to Quote';
        document.getElementById("wishlist-submit-btn").classList.remove('pointer-events-none');
    }

    if(optionInFavStatus == 'true') {
        // the variant option exists in the users favorites
        favIcons.forEach(icon => {
            icon.classList.add('pointer-events-none');
            icon.querySelector('span').innerHTML = 'Added to Favourite';
        });
    } else {
        // Reset the icon if it's not in favorites
        favIcons.forEach(icon => {
            icon.classList.remove('pointer-events-none');
            icon.querySelector('span').innerHTML = 'Add to Favourite';
        });
    }
}


// --------------------------------------------------------------- Export all variables and functions


export { variantSelectField, initVariantSelectField, variantOptionSelectField, initVariantOptionSelectField, variantOptionStatus, variantOptionPrice, variantAssetID, targetGallerySlide, targetGallery, variantOptionToggles, initVariantOptionToggles, variantPriceField, updateVariantPrice, wishlistVariantOptionField, updateWishlistVariantOptionField, listItemImageField, updateListItemImageField, variantOptionTitleSpan, updateVariantOptionTitle, goToGallerySlide, variantStatusAlert, testVariantOptionInList };