console.log('Collection.js, located src/js/modules/collection.js');

function filterToggle(buttonClass) {
  const buttons = document.getElementsByClassName(buttonClass);

  Array.from(buttons).forEach((button) => {
    button.addEventListener('click', function () {
      const dropdownMenuId = button.getAttribute('data-dropdown-toggle');
      const dropdownMenu = document.getElementById(dropdownMenuId);
      const dropdownIcon = button.querySelector('svg');

      // Rotate icon immediately
      dropdownIcon.classList.toggle('rotate-180');
    });
  });
  
  document.addEventListener('click', function (event) {
    Array.from(buttons).forEach((button) => {
      const dropdownMenuId = button.getAttribute('data-dropdown-toggle');
      const dropdownMenu = document.getElementById(dropdownMenuId);
      const dropdownIcon = button.querySelector('svg');

      if (
        dropdownMenu &&
        !button.contains(event.target) &&
        !dropdownMenu.contains(event.target)
      ) {
        dropdownIcon.classList.remove('rotate-180');
      }
    });
  });
}

function updateLabelFromRange(rangeId, labelId) {
  var range = document.getElementById(rangeId).value;
  document.getElementById(labelId).value = range;
}

function updateRangeFromInput(labelId, rangeId) {
  var input = document.getElementById(labelId).value;
  document.getElementById(rangeId).value = input;
}

document.addEventListener('DOMContentLoaded', () => {
  filterToggle('filterButton');
});

function updatePriceRange() {
  const minPrice = document.getElementById('minPrice').value;
  const maxPrice = document.getElementById('maxPrice').value;

  document.getElementById('minPriceLabel').innerText = minPrice;
  document.getElementById('maxPriceLabel').innerText = maxPrice;
}

// Collection item variant hover images

/* function attachCollectionItemVariantHovers(variants) {
  variants.forEach((variant) => {
    variant.addEventListener('click', (event) => {
      // get the image we want to load
      let variantImage = variant.getAttribute('data-variant-hover-image');
      // get the target image holder
      let variantImageHolder = variant.getAttribute(
        'data-target-hover-image-holder'
      );
      let variantImageHolderID = document.getElementById(variantImageHolder);

      // reveal the image holder
      variantImageHolderID.style.opacity = '1';

      // load the image
      variantImageHolderID.style.backgroundImage = 'url(' + variantImage + ')';
    });

    variant.addEventListener('mouseleave', (event) => {
      let variantImageHolder = variant.getAttribute(
        'data-target-hover-image-holder'
      );
      let variantImageHolderID = document.getElementById(variantImageHolder);

      // hide the image holder
      variantImageHolderID.style.opacity = '0';

      // remove the background image
      variantImageHolderID.style.backgroundImage = null;
    });
  });
}

let variantHoverIcons = document.querySelectorAll('[data-variant-hover-image]');

if (variantHoverIcons.length !== 0) {
  attachCollectionItemVariantHovers(variantHoverIcons);
}
attachCollectionItemVariantHovers */

function attachCollectionItemVariantHovers(variants) {
    variants.forEach((variant) => {
      variant.addEventListener('click', (event) => {
        // get the image we want to load
        let variantImage = variant.getAttribute('data-variant-hover-image');
        // get the target image holder
        let variantImageHolder = variant.getAttribute(
          'data-target-hover-image-holder'
        );
        let variantImageHolderID = document.getElementById(variantImageHolder);
  
        // reveal the image holder
        /* variantImageHolderID.style.opacity = '1'; */
  
        // load the image
        /* variantImageHolderID.src = 'variantImage';
        console.log('Collection.js',  variantImageHolderID) */
      });
    });
  }
  
  let variantHoverIcons = document.querySelectorAll('[data-variant-hover-image]');
  
  if (variantHoverIcons.length !== 0) {
    attachCollectionItemVariantHovers(variantHoverIcons);
  }
// Clear filters buttons

let clearFilterButtons = document.querySelectorAll('.clear-filters-button');

function attachClearFilterButtons(buttons) {
  buttons.forEach((button) => {
    button.addEventListener('click', (event) => {
      formFilterInputs.forEach((formFilterInput) => {
        formFilterInput.checked = false;
      });

      updateFilterResults();

      event.preventDefault();
    });
  });
}

attachClearFilterButtons(clearFilterButtons);

// Our Fetch API Functions for loading results via Javascript

const dynamicDataHolder = document.getElementById('collection-data-holder');
const loadingAnimation = document.getElementById('dynamic-loading-animation');

let sortOrder = 'score';

function updateFilterResults() {
  // Update our query string based on user input
  const itemCategories = Array.from(
    document.querySelectorAll('[name="item-categories[]"]:checked')
  ).map(el => el.value).join('|');

  const colours = Array.from(
    document.querySelectorAll('[name="colours[]"]:checked')
  ).map(el => el.value).join('|');

  const materials = Array.from(
    document.querySelectorAll('[name="materials[]"]:checked')
  ).map(el => el.value).join('|');

  const minPrice = document.querySelector('[name="minPrice"]').value;
  const maxPrice = document.querySelector('[name="maxPrice"]').value;

  document.querySelector('#minPriceLabel').value = minPrice;
  document.querySelector('#maxPriceLabel').value = maxPrice;

  sortOrder = sortSwitcherSelectField.options[sortSwitcherSelectField.selectedIndex].value;

  const queryString = `?item-categories=${itemCategories}&colours=${colours}&materials=${materials}&min-price=${minPrice}&max-price=${maxPrice}&sort=${sortOrder}`;
  console.log(queryString);

  const filterQueryString = window.location.href.split('?')[0] + queryString;

  console.log('collection.js', filterQueryString);

  // Use the JS Fetch API to replace the content on the page without reloading
  fetch(filterQueryString)
    .then(function (response) {
      if (!response.ok) {
        // If the response has a status code other than 2xx, log the status text
        throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
      }
      return response.text();
    })
    .then(function (html) {

      // Let's parse and display our data and run any other animations etc
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const parsedDynamicData = doc.getElementById('collection-filter-results');

      // Clear the contents of the dynamicDataHolder in anticipation of adding our new content
      while (dynamicDataHolder.firstChild) {
        dynamicDataHolder.removeChild(dynamicDataHolder.firstChild);
      }

      // Add the new data to the dynamicDataHolder
      dynamicDataHolder.appendChild(parsedDynamicData);

      // Everything's loaded, let's hide our animation
      /* loadingAnimation.classList.remove('visible'); */

      // Let's update the browser's URL, mainly for bookmarking or sharing the filtered results
      const nextState = { additionalInformation: 'Page updated' };
      console.log(filterQueryString);
      window.history.replaceState(nextState, 'Collection', filterQueryString);

      // We need to reattach our event listeners for variant mouseenter / mouseleave functions
       const variantHoverIcons = document.querySelectorAll('[data-variant-hover-image]');
      attachCollectionItemVariantHovers(variantHoverIcons);

      // We need to reattach our clear filters buttons as some may have been loaded via Fetch
      const clearFilterButtons = document.querySelectorAll('.clear-filters-button');
      attachClearFilterButtons(clearFilterButtons);
    })
    .catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    })
    .finally(function() {
      window.initLoadMore();
    });
  // Hide the loading animation
}


// Attach our event listener to all form input fields with the class .form-filter-input

function attachFormFilterInputs() {
  let formFilterInputs = document.querySelectorAll('.form-filter-input');

  // Add an overlay div if it doesn't exist
  let overlay = document.getElementById('loading-overlay');
  if (!overlay) {
    overlay = document.createElement('div');
    overlay.id = 'loading-overlay';
    overlay.style.cssText = 'position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.7); z-index: 1000; display: none;';
    document.body.appendChild(overlay);
  }

  formFilterInputs.forEach((formFilterInput) => {
    formFilterInput.addEventListener('change', (event) => {
      loadingAnimation.classList.add('visible');
      overlay.style.display = 'block'; // Show overlay
      setTimeout(function () {
        updateFilterResults();
        overlay.style.display = 'none'; // Hide overlay after loading
      }, 1000);
    });
  });

  document.getElementById('minPrice').addEventListener('change', (event) => {
    loadingAnimation.classList.add('visible');
    overlay.style.display = 'block';
    setTimeout(function () {
      updateFilterResults();
      overlay.style.display = 'none';
    }, 1000);
  });

  document.getElementById('maxPrice').addEventListener('change', (event) => {
    loadingAnimation.classList.add('visible');
    overlay.style.display = 'block';
    setTimeout(function () {
      updateFilterResults();
      overlay.style.display = 'none';
    }, 1000);
  });
}

attachFormFilterInputs();

// --------------------------------------------------------------- Load New Category Function. This loads new filters and results from a specific category field. Filters are cleared as they may not be relevant to the new category.

const dynamicFilterHolder = document.getElementById('dynamic-filter-holder');

function loadNewCategory(dynamicUrl) {
  console.log(dynamicUrl);

  fetch(dynamicUrl)
    .then(function (response) {
      // The API call was successful!
      return response.text();
    })
    .then(function (html) {
      // lets parse and display our data and run any other animations etc

      // Use appendChild to only insert the content of the dynamic-data-content div
      // Convert the HTML string into a document object
      let parser = new DOMParser();
      let doc = parser.parseFromString(html, 'text/html');
      let parsedDynamicData = doc.getElementById('collection-filter-results');
      let parsedDynamicFilterData = doc.getElementById('dynamic-filters');

      // clear the contents of the dynamicDataHolder in anticipation of adding our new content
      while (dynamicDataHolder.firstChild) {
        dynamicDataHolder.removeChild(dynamicDataHolder.firstChild);
      }

      // add the new data to the dynamicDataHolder
      dynamicDataHolder.appendChild(parsedDynamicData);

      // Load the new filter menus
      while (dynamicFilterHolder.firstChild) {
        dynamicFilterHolder.removeChild(dynamicFilterHolder.firstChild);
      }

      // add the new data to the dynamicDataHolder
      dynamicFilterHolder.appendChild(parsedDynamicFilterData);

      // Reattach event listeners to our filter menus
      attachFormFilterInputs();

      // Everythings loaded lets hide our animation
      loadingAnimation.classList.remove('visible');

      // let's update the browsers url, mainly for bookmarking or sharing the filtered results
      const nextURL = dynamicUrl;
      const nextTitle = 'Collection';
      const nextState = {
        additionalInformation: 'Page updated',
      };

      // This will replace the current entry in the browser's history, without reloading
      window.history.replaceState(nextState, nextTitle, nextURL);

      // We need to reattach our event listeners for variant mouseenter / mouseleave functions
      /* let variantHoverIcons = document.querySelectorAll(
        '[data-variant-hover-image]'
      );
      attachCollectionItemVariantHovers(variantHoverIcons); */
    })
    .catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
}
// --------------------------------------------------------------- Sort switcher switches url based on the users selection

const sortSwitcherSelectField = document.getElementById('sort-switcher');

if (
  typeof sortSwitcherSelectField != 'undefined' &&
  sortSwitcherSelectField != null
) {
  sortSwitcherSelectField.addEventListener('change', (event) => {
    loadingAnimation.classList.add('visible');

    setTimeout(function () {
      updateFilterResults();
    }, 1000);
  });
}

// Category filters switch url based on the users selection

const formCategoryInputs = document.querySelectorAll('.form-category-input');

formCategoryInputs.forEach((formCategoryInput) => {
  formCategoryInput.addEventListener('change', (event) => {
    // if we are checking this input we want to go this particular category

    if (formCategoryInput.checked) {
      let newCategory = formCategoryInput.value;

      loadingAnimation.classList.add('visible');

      setTimeout(function () {
        loadNewCategory(newCategory);
      }, 1000);
    } else {
      // if we are UN-checking this input we want to go back a segment, for this we use a data attribute set on the checkbox 'data-parent-url'
      let formCategoryInputParentUrl =
        formCategoryInput.getAttribute('data-parent-url');
      window.location = formCategoryInputParentUrl;
    }
  });
});

// Apply filters submit button only used if you uncomment the 'filter' submit button

document
  .querySelector('.js-filtersForm')
  .addEventListener('submit', function (e) {
    e.preventDefault();
    const colours = Array.from(
      document.querySelectorAll('[name="colours[]"]:checked'),
      function (el) {
        return el.value;
      }
    ).join('|');
    const materials = Array.from(
      document.querySelectorAll('[name="materials[]"]:checked'),
      function (el) {
        return el.value;
      }
    ).join('|');
    const queryString = '?colours=' + colours + '&materials=' + materials;

    // console.log( window.location.href.split('?')[0] + queryString);

    window.location = window.location.href.split('?')[0] + queryString;
  });
