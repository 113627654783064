// --------------------------------------------------------------- Reload Quote

const dynamicQuoteHolder = document.getElementById("dynamic-quote-holder");


let quoteParser = null;
let quoteDoc = null;
let parsedQuoteData = null;
let quoteCount = null;

function reloadQuote() {
	fetch("/")
		.then(function (response) {
			// The API call was successful!
			return response.text();
		})
		.then(function (html) {
			// Use appendChild to only insert the content of the dynamic-data-content div
			// Convert the HTML string into a document object
			
			quoteParser = new DOMParser();
			quoteDoc = quoteParser.parseFromString(html, "text/html");
			parsedQuoteData = quoteDoc.getElementById("quote-list");

			if (
				typeof dynamicQuoteHolder != "undefined" &&
				dynamicQuoteHolder != null
			) {
				// clear the contents of the dynamicDataHolder in anticipation of adding our new content
				while (dynamicQuoteHolder.firstChild) {
					dynamicQuoteHolder.removeChild(dynamicQuoteHolder.firstChild);
				}

				// add the new data to the dynamicDataHolder
				dynamicQuoteHolder.appendChild(parsedQuoteData);
			}

			quoteCount = document
				.getElementById("quote-list-items")
				.getAttribute("data-quote-item-count");

			console.log("Reload the quote with quote count: " + quoteCount);

			updateQuoteCartCounters(quoteCount);

			initItemQuantityFields();
		});
}

// --------------------------------------------------------------- AJAX Wishlist form


let wishListForm = null;
let formSuccessMessage = null;
let wishListFormData = null;
let itemVariantOptionsSection = null;
let wishlistVariantOptionFields = null;
let checkedRadio = null;

function submitWishlistForm(targetFormID) {
	console.log("form submitted");

	wishListForm = targetFormID;

	formSuccessMessage = targetFormID.getAttribute("data-form-success-message");

	wishListFormData = new FormData(wishListForm);

	fetch("/", {
		method: "post",
		body: wishListFormData,
		headers: {
			Accept: "application/json",
			"X-Requested-With": "XMLHttpRequest",
		},
	}).then(function (response) {
		response
			.json()
			.then(function (wishListFormData) {
				console.log(wishListFormData);

				reloadQuote();

				/* document.getElementById("wishlist-submit-btn").disabled = true;
				document.getElementById("variant-status").innerHTML =
				"This selection has been added to your quote."; */
                document.getElementById("wishlist-submit-btn").disabled = true;
                document.getElementById("wishlist-submit-btn").value = 'Added to Quote';
                document.getElementById("wishlist-submit-btn").classList.add('pointer-events-none');
                document.getElementById("wishlist-submit-btn").classList.add('uppercase');
            // we only want to run this function if the item has variant options
				itemVariantOptionsSection = document.getElementById(
					"item-variant-options-section"
				);

				if (
					typeof itemVariantOptionsSection != "undefined" &&
					itemVariantOptionsSection != null
				) {
					// ensure the current variant option has its data-variant-option-in-list attribute set to true

					wishlistVariantOptionFields = document.getElementsByName(
						"options[wishlistVariantOption]"
					);

					// determine the type of input field select or radio
					if (wishlistVariantOptionFields[0].nodeName == "SELECT") {
						wishlistVariantOptionFields[0].options[
							wishlistVariantOptionFields[0].selectedIndex
						].setAttribute("data-variant-option-in-list", "true");
					} else {
						checkedRadio = Array.from(wishlistVariantOptionFields).find(
							(radio) => radio.checked
						);

						checkedRadio.parentNode.setAttribute(
							"data-variant-option-in-list",
							"true"
						);
					}
				}
			})
			.catch(function (err) {
				// There was an error
				console.warn("Something went wrong.", err);
			});
	});
}

// --------------------------------------------------------------- Wishlist Submit Buttons

const wishListFormItemID = document.getElementById("wishlist-form");
let wishListsubmitBtns = document.querySelectorAll(".wishlist-submit-btn");

const favListItemID = document.getElementById("fav-list-form");


function initWishListSubmit(submitBtns) {
	submitBtns.forEach((submitBtn) => {
		submitBtn.addEventListener("click", (event) => {
			// Submit the form via Fetch
			submitWishlistForm(wishListFormItemID, submitBtn);

			event.preventDefault();
		});
	});
}

// -----------------------------  Quote quantity field inputs

let quoteForm = null;
let quoteData = null;

const loadingAnimation = document.getElementById('dynamic-loading-animation');

function updateQuoteQuantities() {
	quoteForm = document.querySelector("#quote-wishlist-form");
	quoteData = new FormData(quoteForm);

	var sideCart = document.getElementById("side-cart");

	// Show loading animation immediately
	loadingAnimation.classList.add('visible');
	// Add grey overlay
	sideCart.classList.add('opacity-50', 'bg-gray-100');
	sideCart.classList.add('pointer-events-none');

	// Add minimum delay of 500ms
	const minDelay = new Promise(resolve => setTimeout(resolve, 500));

	fetch("/", {
		method: "post",
		body: quoteData,
		headers: {
			Accept: "application/json",
			"X-Requested-With": "XMLHttpRequest",
		},
	})
	.then(function (response) {
		return Promise.all([response.json(), minDelay]);
	})
	.then(function ([quoteData]) {
		console.log(quoteData);
		// Reload our quote so we get the latest data
		reloadQuote();
	})
	.catch(function (err) {
		// There was an error
		console.warn("Something went wrong.", err);
	})
	.finally(function () {
		loadingAnimation.classList.remove('visible');
		sideCart.classList.remove('opacity-50', 'bg-gray-100');
		sideCart.classList.remove('pointer-events-none');
	});
}

// ----------------------------- Quote Item Quantity Fields

let itemQuantityFields = null;
let removeFieldId = null;
let removeField = null;
let itemContainerId = null;
let itemContainer = null;

function initItemQuantityFields() {
	itemQuantityFields = document.querySelectorAll(
		".quote-panel-quantity-field"
	);

	itemQuantityFields.forEach((itemQuantityField) => {
		itemQuantityField.addEventListener("change", (event) => {
			// Add a 300ms delay so this event doesn't bubble the Fetch requests on the server
			setTimeout(function () {
				// get the value of this field, if its zero we want to check the hidden remove field for this item
				if (itemQuantityField.value < 1) {
					// get the correct remove field
					removeFieldId =
						itemQuantityField.getAttribute("data-remove-field");
					removeField = document.getElementById(removeFieldId);

					removeField.checked = true;

					updateQuoteQuantities();

					// Hide the item from the user (even though its been removed from the list it's still present)
					itemContainerId = itemQuantityField.getAttribute(
						"data-item-container"
					);
					itemContainer = document.getElementById(itemContainerId);

					itemContainer.remove();
				} else {
					updateQuoteQuantities();
				}
			}, 300);
		});
	});
}

// ----------------------------- Update Quote Counters

let quoteCartCounters = null;

function updateQuoteCartCounters(newQuoteCount) {
	console.log(newQuoteCount);

	quoteCartCounters = document.querySelectorAll(".quote-cart-count");

	quoteCartCounters.forEach((quoteCartCounter) => {
		quoteCartCounter.innerText = newQuoteCount;

		if (newQuoteCount > 0) {
			quoteCartCounter.classList.add("quote-cart-count-visible");
		} else {
			quoteCartCounter.classList.remove("quote-cart-count-visible");
		}
	});
}

// ----------------------------- Update Fav Counters

let quoteFavCounters = null;

function updateQuoteFavCounters(newFavCount) {
	console.log(newFavCount);

	quoteCartCounters = document.querySelectorAll(".quote-fav-count");

	quoteCartCounters.forEach((quoteCartCounter) => {
		quoteCartCounter.innerText = newFavCount;

		if (newFavCount > 0) {
			quoteCartCounter.classList.add("quote-fav-count-visible");
		} else {
			quoteCartCounter.classList.remove("quote-fav-count-visible");
		}
	});
}

export {
	dynamicQuoteHolder,
	quoteParser,
	quoteDoc,
	parsedQuoteData,
	quoteCount,
	reloadQuote,
	wishListForm,
	formSuccessMessage,
	wishListFormData,
	itemVariantOptionsSection,
	wishlistVariantOptionFields,
	checkedRadio,
	wishListFormItemID,
	wishListsubmitBtns,
	initWishListSubmit,
	quoteForm,
	quoteData,
	updateQuoteQuantities,
	itemQuantityFields,
	removeFieldId,
	removeField,
	itemContainerId,
	itemContainer,
	initItemQuantityFields,
	quoteCartCounters,
	updateQuoteCartCounters,
    updateQuoteFavCounters
};
