// This function is to call the API to get the products from Wishlist
// | CraftCMS | Wishlist | API | Products |
let productIdSet = new Set();

// ----------------- Fetch Product List -----------------   //
async function fetchProductList(listId) {
    const query = `
    {
        wishlistItems(listId: ${listId}) {
            ... on Item {
                id
                variantOptionsTitle
                wishlistVariantOption
                wishlistVariantUrl
                newOptions
                quantity
                element {
                    id
                    title
                }
            }
        }
    }
`;

    try {
        const response = await fetch('/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.WISHLIST_TOKEN}`, // Use the global token
            },
            body: JSON.stringify({ query }),
        });
        const data = await response.json();

        /* console.info ('1. fetchProductList function loaded');
        console.info ('1. fetchProductList fetched data:', data); */
        updateWishlistData(data);

       /*  productIdSet = new Set(data.data.wishlistItems.map(item => ({
            uid: item.element.id +'_' + item.wishlistVariantOption,
            id: item.element.id,
            title: item.element.title,
            option: item.wishlistVariantOption,
            
        }))); */
        // Store wishlist in session storage for later use
        /* sessionStorage.setItem('WM_wishListData', JSON.stringify(Array.from(data.data.wishlistItems)));
        sessionStorage.setItem('WM_productSet', JSON.stringify(Array.from(productIdSet))); */
        /* console.info ('1. fetchProductList fetched productIdSet:', productIdSet); */
    } catch (error) {
        console.error('Wishlist is empty!', error);
    }
}

// ----------------- Refresh -----------------   //
function refreshWishlistStatus(productId, firstVariantDetailsId, secondVariantDetailsTitle,listId,button) {
    const variantId = firstVariantDetailsId;
    const uid = `${variantId}_${secondVariantDetailsTitle}`;
    const wishlistStatus = document.querySelector('#wishlist-status-' + productId);

    /* console.warn ('refreshWishlistStatus function: uid:', uid); */
    /* console.warn(window.isProductInList(uid)); */
    
    if (window.isProductInList(uid)) {
        // Product is in list
        /* console.warn ('refreshWishlistStatus function: Product is in the list!'); */
        addToCartButtonDisabled(button, productId);
    }else{
        // Product is not in list
        /* console.warn ('refreshWishlistStatus function: Product is not in the list!'); */
        addToCartButtonEnabled(button, productId);
    }
}


// ----------------- Check if product in list -----------------   //
function isProductInList(productId) {
    /* console.warn ('isProductInList function: productId:', productId); */

    if (!productIdSet.size) {
        console.error('2. Product list not loaded yet');
        return false;
    }
    
    // Convert productIdSet to array and check if any item's uid matches productId
    const found = Array.from(productIdSet).some(item => item.uid === productId);
    return found ;
}

window.fetchProductList = fetchProductList;
window.isProductInList = isProductInList;

async function initializeProductCheck(listId) {
    
    // First fetch the product list
    await fetchProductList(listId);

    
    // Get all product elements (adjust the selector based on your HTML structure)
    const productElements = document.querySelectorAll('[data-product-id]');
    const mainVariantElements = document.querySelectorAll('[main-variant-id]');
    /* console.info ('3. initializeProductCheck function loaded, counted productElements:', productElements.length); */

    productElements.forEach(product => {
        const productDataId = product.getAttribute('data-product-id');
        const productId = product.querySelector('input[name="elementId"]').value +'_'+ product.querySelector('input[name="fields[wishlistVariantOption]"]').value;

        /* console.warn ('initializeProductCheck function: productId:', productId); */
        
        let form = document.querySelector('#quick-add-to-cart-' + productDataId);
        let button = form.querySelector('button[type="submit"]');

        if (isProductInList(productId)) {
            addToCartButtonDisabled(button, productDataId);
        } else {
            addToCartButtonEnabled(button, productDataId);


        }
    });
}

// ----------------- Update existing cached sessison cart ---------------------- //
// This function is used for add to cart ajax success.
// Where updateWishlistData is called when page loaded.

function updateWishlistDataFromForm(response) {
    if (!response?.items || !Array.isArray(response.items)) {
        console.error('Invalid response format');
        return;
    }

    // Get existing data from session storage and convert to Set
    const existingSetData = new Set(JSON.parse(sessionStorage.getItem('WM_productSet') || '[]'));
    
    // Create new items set from response
    const newItems = response.items.map(item => ({
        uid: item.elementId + '_' + item.wishlistVariantOption,
        id: item.elementId.toString(),
        title: item.title,
        option: item.wishlistVariantOption
    }));

    // Merge existing and new items
    productIdSet = new Set([...existingSetData, ...newItems]);

    // Store merged wishlist in session storage
    const finalItems = Array.from(productIdSet);

    // Store merged wishlist in session storage
    /* sessionStorage.setItem('WM_wishListData', JSON.stringify(finalItems)); */
    sessionStorage.setItem('WM_productSet', JSON.stringify(Array.from(finalItems)));
    
    /* console.info('updateWishlistDataFromForm| Wishlist data updated:', productIdSet); */
    return productIdSet;
}

function updateWishlistData(data) {
    if (!data?.data?.wishlistItems) {
        console.error('Invalid wishlist data format');
        return;
    }

    productIdSet = new Set(data.data.wishlistItems.map(item => ({
        uid: item.element.id + '_' + item.wishlistVariantOption,
        id: item.element.id,
        title: item.element.title,
        option: item.wishlistVariantOption
    })));

    // Store wishlist in session storage
    /* sessionStorage.setItem('WM_wishListData', JSON.stringify(Array.from(data.data.wishlistItems))); */
    sessionStorage.setItem('WM_productSet', JSON.stringify(Array.from(productIdSet)));
    
    /* console.info('updateWishlistData| Wishlist data updated:', productIdSet); */
    return productIdSet;
}

window.initializeProductCheck = initializeProductCheck;
window.refreshWishlistStatus = refreshWishlistStatus;
window.updateWishlistData = updateWishlistData;
window.updateWishlistDataFromForm = updateWishlistDataFromForm;