let quotePanelToggleBtns = document.querySelectorAll('.quote-panel-toggle');

const quoteListPanel = document.getElementById('quote-list-panel');

function initQuotePanelToggleBtns(toggleBtns) {

	toggleBtns.forEach(toggleBtn => {

		toggleBtn.addEventListener('click', (event) => {

			quoteListPanel.classList.toggle('quote-list-panel-hidden');

			event.preventDefault();

		});

	});
}



export { quotePanelToggleBtns, quoteListPanel, initQuotePanelToggleBtns };